@import 'common';

// --------------------------------------------------
// Section

.tagline {
	margin-top: 70px;
	text-align: center;
}

.section {
	margin-top: $section-margin;
	h2 {
		font-weight: bold;
	}
}

#profile {
	text-align: center;

	.name.ja {
		font-size: 1.5rem;
	}
	.name.en {
		font-size: 1.2rem;
	}
	.role {
		margin-top: 20px;
	}
	.history {
		margin-top: 30px;
		font-size: 1.1rem;
		line-height: 150%;
	}
	.account {
		margin-top: 20px;
		font-size: 1.1rem;
	}
}

#works {
	max-width: $content-max-width;
	margin-left: auto;
	margin-right: auto;

	h2 {
		display: none;
	}

	ul {
		li {
			width: 75%;
			max-width: $item-max-width;

			&:not(:first-child) {
				margin-top: 40px;
			}

			&:nth-child(odd) {
				margin-left: auto;
			}
			&:nth-child(even) {
				margin-right: auto;
			}

			@include media(mobile) {
				width: auto;
				max-width: none;
				margin-left: 0;
				margin-right: 0;
			}

			.featured-image {
				@include disable-text();
				text-align: center;
				img {
					width: auto;
					height: auto;
					max-width: 100%;
					max-height: $item-max-width;

					@include media(mobile) {
						width: 100%;
						max-width: none;
						max-height: none;
					}
				}
				a {
					display: block;
					width: 100%;
					height: 100%;

					//@include hover-fade();
					background-color: #fff;
					&:hover {
						img {
							filter: brightness(0);
						}
					}
				}
			}
			.title {
				margin-top: 20px;
				text-align: center;
				font-size: 1.5rem;
				line-height: 100%;
				//@include no-effect-link();
			}
			.credit {
				margin-top: 30px;
				text-align: center;
				font-size: 0.8rem;
				line-height: 150%;
				strong {
					font-weight: normal;
				}
			}
			.date {
				margin-top: 30px;
				text-align: center;
				font-size: 0.8rem;
				line-height: 100%;
			}

			.text-block {
				@include media(mobile) {
					$margin: 10px;
					margin-left: $margin;
					margin-right: $margin;
				}
			}
		}
	}

	.more {
		margin-top: 40px;
		text-align: center;
		font-size: 1.1rem;
	}
}

#contact {
	text-align: center;

	.mail {
		font-size: 1.4rem;
	}
	.address {
		font-size: 1.2rem;
		line-height: 150%;
	}
	.address.ja {
		margin-top: 20px;
	}
	.address.en {
		margin-top: 15px;
	}
	.map.link {
		margin-top: 20px;
		font-size: 1.1rem;
	}
	.map.canvas {
		margin: 0 auto;
		width: 100%;
		height: 300px;
		max-width: $content-max-width;
	}
}


#office {
	text-align: center;

	.address.ja {
		margin-top: 20px;
	}
	.address.en {
		margin-top: 10px;
	}
	.map.canvas {
		margin-top: 20px;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		height: 300px;
		max-width: $content-max-width;
	}
	.map.link {
		margin-top: 15px;
		font-size: 1.1rem;
	}
}
