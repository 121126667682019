@charset "utf-8";





// --------------------------------------------------
// Vars

$default-font-size: 14px;

$text-color: #333;
$text-color-sub: #666;
//$text-color-link: #06c;

$text-color-link: #fff;
$background-color-link: #000;

$border-color: #eee;

$section-margin: 80px;
$navigation-height: 40px;

$default-font-size: 14px;

$content-max-width: 1400px;
$item-max-width: 800px;

$breakpoints: (
	"mobile": "screen and (max-width: 600px)",
	"tablet": "screen and (max-width: 900px)",
) !default;





// --------------------------------------------------
// Utils

@mixin clearfix() {
	zoom: 1;
	&:before, &:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin font-sans-serif() {
	font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Meiryo", "MS P Gothic", "ＭＳ Ｐゴシック", Osaka, Helvetica, Arial, sans-serif;
}

@mixin font-serif() {
	font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

@mixin font-monospace() {
	font-family: Consolas, 'Courier New', Courier, Monaco, monospace;
}

@mixin disable-text() {
	font-size: 0;
	line-height: 0;
	letter-spacing: 0;
}

@mixin enable-text($font-size:14px, $line-height:150%, $letter-spacing:normal) {
	font-size: $font-size;
	line-height: $line-height;
	letter-spacing: $letter-spacing;
}

@mixin hover-fade($alpha:0.3, $duration:0.2s, $easing:ease-out) {
	-webkit-transition: $duration $easing;
	-moz-transition: $duration $easing;
	-o-transition: $duration $easing;
	transition: $duration $easing;
	&:hover {
		cursor: pointer;
		opacity: $alpha;
		filter: alpha(opacity=$alpha);
	}
}
.touchevents {
	*:hover {
		opacity: inherit !important; //iOS8でのhoverバグ回避
	}
}

@mixin vertical() {
	writing-mode: vertical-rl;
	-webkit-writing-mode: vertical-rl;
	//text-orientation: upright;
	//-webkit-text-orientation: upright;
}

@mixin no-effect-link() {
	a {
		text-decoration: none;
		color: $text-color;
		background-color: #fff;
	}
}

@mixin fit-image($min-width:auto, $max-width:auto) {
	display: block;
	width: 100%;
	min-width: $min-width;
	max-width: $max-width;
}

@mixin media($breakpoint: md) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

@mixin selection($color:#fff, $background:#000) {
	::-moz-selection {
		color: $color;
		background: $background;
	}
	::selection {
		color: $color;
		background: $background;
	}
}





// --------------------------------------------------
// Reset

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
}

ul {
	margin: 0;
	padding: 0;
}

li {
	list-style-type: none;
}





// --------------------------------------------------
// Common

html {
	font-size: 62.5%;
	overflow-y: scroll; // always display vertical scrollbar
	&.svg {
		.svg-alt {
			display: none !important;
		}
	}
	&.no-svg {
		.svg {
			display: none !important;
		}
	}
}

body {
	font-family: 'BitstreamVeraSansMonoRoman',Arial,Verdana,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
	font-size: $default-font-size;
	color: $text-color;
	line-height: 200%;
	text-justify: distribute;
	font-kerning: auto;

	@include selection();

	@include media(mobile) {
		font-size: 1.2rem;
	}
}

a {
	//@include hover-fade();
	color: $text-color-link;
	background-color: $background-color-link;
	text-decoration: none;
	&:hover {
		color: $text-color;
		background-color: #fff;
	}
}

@font-face {
	font-family: 'BitstreamVeraSansMonoRoman';
	src: url('../font/veramono-webfont.eot');
	src: url('../font/veramono-webfont.eot?#iefix') format('embedded-opentype'),
	url('../font/veramono-webfont.woff') format('woff'),
	url('../font/veramono-webfont.ttf') format('truetype'),
	url('../font/veramono-webfont.svg#BitstreamVeraSansMonoRoman') format('svg');
	font-weight: normal;
	font-style: normal;
}





// --------------------------------------------------
// Scrollbar
$scrollbar-background-color: #eee;
$scrollbar-thumb-color: #000;
$scrollbar-thumb-color-hover: #ccc;
$scrollbar-width: 10px;

body::-webkit-scrollbar {
	overflow: hidden;
	width: $scrollbar-width;
	height: $scrollbar-width;
	background: $scrollbar-background-color;
}

body::-webkit-scrollbar-thumb {
	overflow: hidden;
	background: $scrollbar-thumb-color;
	&:hover {
		background: $scrollbar-thumb-color-hover;
	}
}





// --------------------------------------------------
// Header / Footer

.header {
	@include no-effect-link();
	margin-top: ($section-margin + $navigation-height);
	.title {
		.name {
			@include vertical();
			display: block;
			margin: 0 auto;
			font-size: 2rem;
			line-height: 0;
		}
	}
}

.footer {
	@include no-effect-link();
}

.logo {
	@include disable-text();
	margin-top: $section-margin;
	text-align: center;

	img {
		width: 60px;
		height: 60px;
	}

	a {
		.default {
			display: inline;
		}
		.hover {
			display: none;
		}

		&:hover {
			.default {
				display: none;
			}
			.hover {
				display: inline;
			}
		}
	}
}





// --------------------------------------------------
// Main

.main {
	margin: $section-margin auto $section-margin;

	$margin: 30px;
	padding-left: $margin;
	padding-right: $margin;

	@include media(mobile) {
		padding-left: 0;
		padding-right: 0;
	}
}

.symbol {
	margin: 30px 0 auto;
	font-size: 2.0rem;
	text-align: center;
}





// --------------------------------------------------
// Navigation

.navigation {
	@include no-effect-link();
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: rgba(255, 255, 255, 1);
	border-bottom: solid $border-color 1px;
	ul {
		margin: 5px auto;
		text-align: center;
		li {
			display: inline;
			margin: 0 10px;
		}
	}
	//a {
	//	@include hover-fade();
	//}
	a:hover {
		color: #000;
		background-color: #000;
	}
}
